<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Верификация адреса</h5>
    <h6>{{ address }}</h6>
    <div>
      <div class="wallet-info" v-if="!mmInstalled">
        <div class="my-3">
          <InlineMessage severity="error"
            >Крипто кошелек не установлен</InlineMessage
          >
        </div>
        <a href="https://metamask.io/download" target="_blank">
          <span
            ><Button label="Установить Крипто кошелек" class="p-button-danger"
          /></span>
        </a>
      </div>

      <div class="wallet-info" v-if="mmInstalled && accountAddress === null">
        <div class="my-3">
          <InlineMessage severity="warn"
            >Крипто кошелек не авторизован</InlineMessage
          >
        </div>
        <Button
          label="Соединиться с Крипто кошельком"
          class="p-button-warning"
          @click.prevent="connectMetamaskAction"
        />
      </div>

      <div class="wallet-info" v-if="mmInstalled && accountAddress !== null">
        <div class="my-3">
          <InlineMessage severity="success"
            >MetaMask подключен к кошельку:
            <span>{{ accountAddress }}</span></InlineMessage
          >
        </div>
      </div>

      <div v-if="accountAddress !== null && accountAddress !== address">
        <div class="my-3">
          <InlineMessage severity="warn">
            <p>Адрес для верификации: {{ address }}</p></InlineMessage
          >
        </div>
        <div class="my-3">
          <InlineMessage severity="error">
            <p>
              Адрес не верифицирован! Пожалуйста измените адрес для верификации
              в <b>Адресах сети</b> или в <b>MetaMask</b> и повторите попытку
            </p></InlineMessage
          >
        </div>
      </div>

      <Button
        v-if="accountAddress !== null && accountAddress !== address"
        label="Адреса сети"
        class="p-button-danger"
        @click="() => $router.push('/eth_addresses')"
      />
    </div>

    <Button
      v-if="
        accountAddress !== null &&
        accountAddress === address &&
        responceValidate === false
      "
      label="Верифицировать адрес"
      class="p-button-primary"
      @click="validateAddress"
    />
    <div v-if="responceValidate" class="my-3">
      <InlineMessage severity="success"
        >Адрес:
        <b
          ><span>{{ accountAddress }}</span> - Верифицирован!</b
        >
      </InlineMessage>
    </div>
    <Button
      v-if="responceValidate"
      label="Адреса сети"
      class="p-button-success"
      @click="() => $router.push('/eth_addresses')"
    />

    <ProgressBar
      v-if="progress"
      mode="indeterminate"
      class="mt-3"
      style="height: 0.2em"
    />
  </div>
</template>

<script>
import MetaMask from '@/mixins/MetaMask'

export default {
  name: 'ValidateAddress',
  data() {
    return {
      progress: false,
      dialogError: false,
      dialogSucces: false,
      responceValidate: false,
      address: null,
      data: null,
    }
  },
  mixins: [MetaMask],
  mounted() {
    this.asyncData()
  },
  methods: {
    async asyncData() {
      this.address = this.$route.params.hash.toLowerCase()
      const resp = await this.axios.get(
        `/api/eth_addresses/get_validate_content/${this.address}`
      )
      this.data = resp.data
    },
    async validateAddress() {
      this.progress = true
      try {
        const dataToSign = this.data.content
        const sign = await this.web3.eth.personal.sign(
          dataToSign,
          this.accountAddress,
          ''
        )
        const resp = await this.axios.get(
          `/api/eth_addresses/sign_and_verify/${this.accountAddress}`,
          {
            params: { signature: sign },
          }
        )
        if (resp.data.result) {
          this.responceValidate = true
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: 'Адрес верифицирован',
            group: 'info',
            life: 5000,
          })
        } else {
          console.log('Error', resp)
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Адрес не верифицирован. Операция отклонена',
            group: 'info',
            life: 5000,
          })
        }
      } catch (error) {
        console.log('Error', error)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Адрес не верифицирован. Операция отклонена',
          group: 'info',
          life: 5000,
        })
      }
      this.progress = false
    },
  },
}
</script>
